.heroSectionContaiener {
  background: url("../../assets/images/herobg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 80px;
  position: relative;
  z-index: 2;
  margin-top: 110px;

  width: 100%;
}
.heroSectionContaiener::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 35, 35, 0.4);

  z-index: -1;
}
.cardContainer {
  display: flex;
  justify-content: center;
  font-family: "MuseoModerno", cursive;
  font-weight: normal;
}
.main {
  padding: 2px 0;
  color: white;
  display: flex;
  flex-direction: column;
  width: 500px;
}
.card_warrper {
  background-color: #001d4b;

  padding: 14px;
}
.fast {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 24px;
  text-transform: uppercase;
}
.fast .text {
  color: #ed5829;
  margin-left: 8px;
  margin-right: 10px;
}
.card_body {
  background-color: #272635;
  padding: 24px 20px 0px 24px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card_body_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stage h1 {
  color: #c21ecb;
  font-size: 26px;
  line-height: 31px;
  font-weight: 400;
}
.stage p {
  color: #bababa;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.money h1 {
  color: #ed5829;
  font-size: 26px;
  line-height: 31px;
  font-weight: 400;
}
.money p {
  color: #bababa;
  font-size: 14px;
}

.sold {
  background-color: white;
  position: relative;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  padding: 12px 0;
  padding-bottom: 12px;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  height: 100%;
}

.solid_desc {
  /* class="text-sm sm:text-sm lg:text-md font-bold flex items-center" */
  font-weight: 700;
  display: flex;
  align-items: center;

  font-size: 16px;
}

.solid_text {
  color: #000000;
  font-size: 22px;
  font-weight: 800;
  margin-left: 2px;
}
.span3 {
  color: #00d0ad;
  font-size: 24px;
}
.solid_left h1 {
  font-size: 22px;
  font-weight: 400;
}

.solid_left p {
  font-size: 12px;
  font-weight: 400;
  color: #ed5829;
  margin-bottom: 5px;
}

.animated_bg {
  background-color: #ed5829;
  height: 10px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.animated_loading {
  padding: 4px 0;
  position: relative;
  width: 80%;
  height: 100%;

  background-color: rgba(0, 208, 173, 1);
}
.animated_loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    hsla(0, 0%, 100%, 0.2) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.2) 0,
    hsla(0, 0%, 100%, 0.2) 75%,
    transparent 0,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  overflow: hidden;
  animation: load 2s linear 0.5s infinite;
}

@keyframes load {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 50px 50px;
  }
}

/* next */
.next {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.count {
  background-color: #1b1a25;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 0.25rem;
}

.token {
  font-weight: 400;
  font-size: 24px;
}
.only {
  font-weight: 400;
  font-size: 14px;
}

.button {
  margin-bottom: 8px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 12px;
  border-width: 1px;
  background-color: #ed5829;
  color: black;
  text-transform: uppercase;
  border-color: black;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 14px;
}
.button:hover {
  opacity: 0.9;
}
.button {
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;

  min-height: 10px;
  min-width: 10px;

  touch-action: manipulation;

  transition: all 0.2s ease, visibility 0s;
  background: #ed5829;
  border-radius: 45px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #fff;
  padding: 12px 30px;
}
.button:hover {
  background: #001d4b;
  box-shadow: 4.95px 4.95px 49px #ed5829;
}
.buy {
  text-align: center;
  padding: 12px 0;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 14px;
  border: none;
  color: rgb(209 213 219);
}

@media screen and (max-width: 520px) {
  .fast {
    font-size: 16px;
  }

  .stage h1 {
    font-size: 18px;
  }
  .stage p {
    font-size: 12px;
  }
  .solid_text {
    font-size: 18px;
  }
  .solid_left h1 {
    font-size: 18px;
  }
  .money h1 {
    font-size: 18px;
  }
  .money p {
    font-size: 12px;
  }
  .next {
    font-size: 11px;
    font-weight: 200;
  }
  .token {
    font-size: 17px;
  }
}

@media screen and (max-width: 320px) {
  .stage h1 {
    font-size: 15px;
  }
  .stage p {
    font-size: 10px;
  }
  .money h1 {
    font-size: 15px;
  }
  .money p {
    font-size: 10px;
  }
  .solid_text {
    font-size: 15px;
  }
  .solid_left h1 {
    font-size: 15px;
  }
  .solid_left p {
    font-size: 10px;
  }
  .next {
    font-size: 10px;
  }
  .token {
    font-size: 14px;
  }
  .only {
    font-size: 12px;
  }
}
