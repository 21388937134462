.footerContainer {
  background: #002e5d;
  padding-top: 50px;
  padding-bottom: 70px;
}
.footer {
  display: flex;
  justify-content: center;
  max-width: 980px;
  width: 90%;
  margin: 0 auto;
  gap: 15px;
}

.text {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 140%;
  opacity: 0.8;
  color: #fff;
  text-align: right;
}
@media only screen and (max-width: 575px) {
  .footer {
    flex-direction: column;
  }
  .text {
    text-align: left;
  }
}
