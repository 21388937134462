.whyJoinContainer {
  background: url("../../assets/images/joiningusbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 80px 0;
  padding-top: 20px;
}

.whyJoin {
  max-width: 1100px;
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.heading {
  font-family: "MuseoModerno", cursive;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  color: #ff4040;

  width: 100%;
}
.textContainer {
  padding-top: 100px;
}
.text {
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 140%;
  padding: 10px 0;
}
@media only screen and (max-width: 991px) {
  .whyJoin {
    flex-direction: column-reverse;
  }
  .textContainer {
    padding-top: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .heading {
    font-size: 36px;
  }
}
